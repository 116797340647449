jQuery(document).on('ready turbolinks:load', function() {
  // console.log("datepicker.js file loaded");
  // if (typeof(jQuery.ui.datepicker) != 'undefined'){
  jQuery('#post_start').datepicker({
    setDefaultDate: true,
    // format: 'yy-mm-dd',
    dateFormat: "yy-mm-dd",
    firstDay: 1,
    i18n: {

    }
  });
  jQuery('#post_end').datepicker({
    setDefaultDate: true,
    dateFormat: "yy-mm-dd",
    firstDay: 1,
    minDate: new Date(),
  });
});


// $(document).on('input', '#post_start', function () {
//   console.log("datepicker.js file loaded");
//   $('#post_start').datepicker({
//     setDefaultDate: true,
//     // format: 'yy-mm-dd',
//     dateFormat: "yy-mm-dd",
//     firstDay: 1,
//     i18n: {

//     }
//   });
//   $('#post_end').datepicker({
//     setDefaultDate: true,
//     dateFormat: "yy-mm-dd",
//     firstDay: 1,
//     minDate: new Date(),
//   });
// });
