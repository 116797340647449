// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= // require_self
//= // require bootstrap.mintart()
require("@rails/ujs").start()
require("turbolinks").start() //= require bootstrap.mintart()
require("@rails/activestorage").start()
require("channels") //= require_self
require("jquery")
// require("jquery-ui")
//= // require jquery
//= // require jquery.turbolinks

var jQuery = require("jquery");
var $ = jQuery;
// import jQuery from "jquery";
window.jQuery = window.$ = jQuery;
global.$ = global.jQuery = jQuery;
require('bootstrap');
// require("jquery-ui")
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require('webpack-jquery-ui/widgets');

// window.jQuery = $;
// window.$ = $; // lazy fix if you want to use jQuery in your inline scripts.

//= require jquery.easeScroll
//= // require_tree .
//= require_self

// require ("tinymce-jquery")

// require('webpack-jquery-ui')
// require("jquery-ui/datepicker")
// require("jquery-ui/widg//= require jquery-uiets/datepicker")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//= require popper
//= require bootstrap.min

//= require bootstrap-sprockets
//= require jquery.easeScrollts
//= require jquery-ui/widgets/datepicker
//= require jquery-ui/i18n/datepicker-ru
//= require jquery-ui
//= require jquery-ui/datepicker
//= require bootstrap-datetimepicker
//= require tinymce-jquery

//= require jquery-ui/core
//= require jquery-ui/widget
//= require jquery-ui/position

//= require jquery-ui/widget
//= // require jquery-ui/positionjquery-ui/datepicker


/// // import $ from 'jquery'

/// // import 'webpack-jquery-ui/css'


// import 'webpack-jquery-ui'
// import 'webpack-jquery-ui/css'
// import 'webpack-jquery-ui/sortable' // if you need only sortable widget.


//= // require 'packs/news'
//= require packs/datepicker
//= // require_tree .
//= require_directory .

//// require('packs/news')
//// require("packs/custom")
require("packs/datepicker")
//= // require("packs/horoscope")
//= // require("packs/videos")
//= // require("packs/horoscope")
//= // require "packs/datepicker"
